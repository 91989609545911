import EngText from "../language/EnglishLang.json";
import SpanText from "../language/SpanishLang.json";
import * as Models from "../models/GlobalModels";

export function getTranslationData(language: string): Models.languageModel {
  if (language === "English") {
    let infEngData = {
      details: EngText.deets,
      skillSet: EngText.skills,
      codeLang: EngText.codingLang,
    } as Models.infoModel;

    let expEngData = {
      school: EngText.Education,
      work: EngText.work,
    } as Models.experienceModel;

    let data = {
      experience: expEngData,
      information: infEngData,
      footerData: EngText.Footer,
    } as Models.languageModel;

    return data;
  } else {
    let infSpanData = {
      details: SpanText.deets,
      skillSet: SpanText.skills,
      codeLang: SpanText.codingLang,
    } as Models.infoModel;

    let expSpanData = {
      school: SpanText.Education,
      work: SpanText.work,
    } as Models.experienceModel;

    let data = {
      experience: expSpanData,
      information: infSpanData,
      footerData: SpanText.Footer,
    } as Models.languageModel;

    return data;
  }
}
