import React from "react";
import WorkHistory from "../workHistory/WorkHistory";
import Education from "../education/Education";
import * as Models from "../../models/GlobalModels";
import "./ExperiencePanel.css";

interface ExperienceProps {
  expData: Models.experienceModel;
}

function ExperiencePanel({ expData }: ExperienceProps) {
  return (
    <div>
      <div className="w3-container w3-card w3-white w3-margin-bottom">
        <WorkHistory workData={expData.work} />
      </div>

      <div className="w3-container w3-card w3-white">
        <Education eduData={expData.school} />
      </div>
    </div>
  );
}

export default ExperiencePanel;
