import React from "react";
import * as Models from "../../models/GlobalModels";
import "./Deets.css";

interface DeetsProps {
  details: Models.deets;
}

function Deets({ details }: DeetsProps) {
  return (
    <div>
      <p>
        <i className="fa fa-briefcase fa-fw w3-margin-right w3-large w3-text-teal"></i>
        {details.job}
      </p>
      <p>
        <i className="fa fa-home fa-fw w3-margin-right w3-large w3-text-teal"></i>
        {details.town}
      </p>
      <p>
        <i className="fa fa-envelope fa-fw w3-margin-right w3-large w3-text-teal"></i>
        {details.email}
      </p>
      <p>
        <i className="fa fa-phone fa-fw w3-margin-right w3-large w3-text-teal"></i>
        {details.phone}
      </p>
    </div>
  );
}

export default Deets;
