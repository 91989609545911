import React from "react";
import * as Models from "../../models/GlobalModels";
import "./Notables.css";

interface NotableProps {
  notableData: Models.codingLang;
}

function Notables({ notableData }: NotableProps) {
  return (
    <div>
      <p className="w3-large w3-text-theme">
        <b>
          <i className="fa fa-globe fa-fw w3-margin-right w3-text-teal"></i>
          {notableData.sectionTitle}
        </b>
      </p>
      <p>{notableData.first}</p>
      <div className="w3-light-grey w3-round-xlarge">
        <div className="w3-round-xlarge w3-teal dotNetWidth sectionHeight"></div>
      </div>
      <p>{notableData.second}</p>
      <div className="w3-light-grey w3-round-xlarge">
        <div className="w3-round-xlarge w3-teal javaWidth sectionHeight"></div>
      </div>
      <p>{notableData.third}</p>
      <div className="w3-light-grey w3-round-xlarge">
        <div className="w3-round-xlarge w3-teal javascriptWidth sectionHeight"></div>
      </div>
      <p>{notableData.fourth}</p>
      <div className="w3-light-grey w3-round-xlarge">
        <div className="w3-round-xlarge w3-teal xamlWidth sectionHeight"></div>
      </div>
    </div>
  );
}

export default Notables;
