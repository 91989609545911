import React from "react";
import * as Models from "../../models/GlobalModels";
import "./Education.css";

interface EducationProps {
  eduData: Models.schooling;
}

function Education({ eduData }: EducationProps) {
  return (
    <>
      <h2 className="w3-text-grey w3-padding-16 backgroundFiller">
        <i className="fa fa-certificate fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>
        {eduData.sectionTitle}
      </h2>
      <div className="w3-container">
        <h5 className="w3-opacity backgroundFiller">
          <b>{eduData.muTitle}</b>
        </h5>
        <h6 className="w3-text-teal backgroundFiller">
          <i className="fa fa-calendar fa-fw w3-margin-right"></i>May 2020
        </h6>
        <p>
          {eduData.muDegree}. <strong>{eduData.muFocus}</strong>
        </p>
        <hr />
      </div>
      <div className="w3-container panelBottomBuffer">
        <h5 className="w3-opacity backgroundFiller">
          <b>{eduData.haccTitle}</b>
        </h5>
        <h6 className="w3-text-teal backgroundFiller">
          <i className="fa fa-calendar fa-fw w3-margin-right"></i>May 2014
        </h6>
        <p>
          {eduData.haccDegree} <strong>{eduData.haccFocus}</strong>
        </p>
      </div>
    </>
  );
}

export default Education;
