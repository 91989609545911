import React, { useEffect, useState, useCallback } from "react";
import "./ScrollToTopButton.css";

interface ScrollProps {
  lang: string;
}

function ScrollToTopButton({ lang }: ScrollProps) {
  const [showScroll, setShowScroll] = useState<boolean>(false);
  const [toolTipText, setToolTipText] = useState<string>("Scroll to Top");

  // If the page is scrolled more than 300px, show the scroll button
  const checkScrollPosition = useCallback(() => {
    const yOffset = 75;
    if (!showScroll && window.pageYOffset > yOffset) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= yOffset) {
      setShowScroll(false);
    }
  }, [showScroll]);

  useEffect(() => {
    if (lang === "Spanish") {
      setToolTipText("Vuelve al comienzo");
    } else {
      setToolTipText("Scroll to Top");
    }

    window.addEventListener("scroll", checkScrollPosition);
    return () => window.removeEventListener("scroll", checkScrollPosition);
  }, [checkScrollPosition, lang]);

  const handleScrollClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      className={`scroll-top ${showScroll ? "" : "hidden"}`}
      role="button"
      onClick={handleScrollClick}
      onKeyDown={handleScrollClick}
      tabIndex={0}
      aria-label="Scroll to top"
    >
      <i title={toolTipText} className="fa fa-arrow-up fa-2x" />
    </div>
  );
}

export default ScrollToTopButton;
