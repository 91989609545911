import React, { useState } from "react";
import InformationPanel from "./components/informationPanel/InformationPanel";
import ExperiencePanel from "./components/experiencePanel/ExperiencePanel";
import Footer from "./components/footer/Footer";
import * as Models from "./models/GlobalModels";
import * as TextService from "./utilities/LanguageService";
import "./App.css";
import ScrollToTopButton from "./components/scrollToTopButton/ScrollToTopButton";

function App() {
  const [language, setLanguage] = useState<string>("English");
  const [textData, setTextData] = useState<Models.languageModel>(
    TextService.getTranslationData(language)
  );

  const changeLanguage = () => {
    if (language === "English") {
      setLanguage("Spanish");
      setTextData(TextService.getTranslationData("Spanish"));
    } else {
      setLanguage("English");
      setTextData(TextService.getTranslationData("English"));
    }
  };

  return (
    <>
      <ScrollToTopButton lang={language} />
      <span>
        <div className="noPadding">
          <div className="appContainerProps w3-content w3-margin-top">
            <div className="w3-row-padding">
              <div className="w3-third">
                <InformationPanel infoData={textData.information} />
                <br />
              </div>
              <div className="w3-twothird">
                <ExperiencePanel expData={textData.experience} />
              </div>
            </div>
          </div>
          <Footer
            updateLangFunc={changeLanguage}
            footerData={textData.footerData}
          />
        </div>
      </span>
    </>
  );
}

export default App;
