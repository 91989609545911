// Returns environment name such as 'Local', 'Test', 'Prod'
export function getEnvironmentName() {
  return process.env.REACT_APP_ENVIRONMENT_NAME as string;
}

// Returns base URL of connection string to React Frontend
export function getFrontEndConnection() {
  return process.env.REACT_APP_RESUMEEXPRESS_FRONTEND_URL as string;
}

export function getCurrentPathname() {
  const url = window.location.pathname as string;
  if (url === "/") {
    return "home";
  }
  return url;
}

// Returns boolean T/F to indicate if the current environment is Local
export function isLocalEnvironment() {
  let envName: string = process.env.REACT_APP_ENVIRONMENT_NAME as string;
  let isLocal: boolean = ["Local"].includes(envName);
  return isLocal;
}
