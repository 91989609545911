import React from "react";
import Deets from "../deets/Deets";
import Skills from "../skills/Skills";
import Notables from "../notables/Notables";
import Avatar from "../avatar/Avatar";
import * as Models from "../../models/GlobalModels";
import "./InformationPanel.css";

interface InfoProps {
  infoData: Models.infoModel;
}

function InformationPanel({ infoData }: InfoProps) {
  return (
    <>
      <div className="w3-white w3-text-grey w3-card-4">
        <Avatar />
        <div className="w3-container">
          <Deets details={infoData.details} />
          <hr />
          <Skills skillData={infoData.skillSet} />
          <br />
          <Notables notableData={infoData.codeLang} />
          <br />
        </div>
      </div>
    </>
  );
}

export default InformationPanel;
