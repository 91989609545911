import React from "react";
import ProfilePic from "../../assets/MyAvatar.png";
import "./Avatar.css";

function Avatar() {
  return (
    <div className="w3-display-container">
      <img src={ProfilePic} className="profileImg" alt="Avatar" />
      <div className="w3-display-bottomleft w3-container w3-text-black">
        <h2 className="avatarName">Justin LePore</h2>
      </div>
    </div>
  );
}

export default Avatar;
