import React from "react";
import * as Models from "../../models/GlobalModels";
import "./WorkHistory.css";

interface WorkProps {
  workData: Models.workHist;
}

function WorkHistory({ workData }: WorkProps) {
  return (
    <>
      <h2 className="w3-padding-16 w3-text-grey backgroundFiller">
        <i className="fa fa-suitcase fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>
        {workData.sectionTitle}
      </h2>
      <div className="w3-container">
        <h5 className="w3-opacity backgroundFiller">
          <b>{workData.devTitle}</b>
        </h5>
        <h6 className="w3-text-teal backgroundFiller">
          <i className="fa fa-calendar fa-fw w3-margin-right"></i>May 2020 -{" "}
          <span className="w3-tag w3-teal w3-round">{workData.currText}</span>
        </h6>
        <p>{workData.devText}</p>
        <hr />
      </div>
      <div className="w3-container">
        <h5 className="w3-opacity backgroundFiller">
          <b>{workData.hdTitle}</b>
        </h5>
        <h6 className="w3-text-teal backgroundFiller">
          <i className="fa fa-calendar fa-fw w3-margin-right"></i>Oct 2017 - May
          2020
        </h6>
        <p>{workData.hdText}</p>
        <hr />
      </div>
      <div className="w3-container">
        <h5 className="w3-opacity backgroundFiller">
          <b>{workData.antfTitle}</b>
        </h5>
        <h6 className="w3-text-teal backgroundFiller">
          <i className="fa fa-calendar fa-fw w3-margin-right"></i>Jun 2007 -{" "}
          <span className="w3-tag w3-teal w3-round">
            {workData.ongoingText}
          </span>
        </h6>
        <p>{workData.antfText}</p>
        <br />
      </div>
    </>
  );
}

export default WorkHistory;
