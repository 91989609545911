import React from "react";
import * as Models from "../../models/GlobalModels";
import * as EnvMgr from "../../utilities/envUtil";
import "./Footer.css";

interface FooterProps {
  updateLangFunc: () => void;
  footerData: Models.footerModel;
}

function Footer({ updateLangFunc, footerData }: FooterProps) {
  const getCsvDownloadLink = (relativePath: string) => {
    const urlHardpath = EnvMgr.getFrontEndConnection();
    const newUrl = `${urlHardpath}/${relativePath}`;
    return newUrl;
  };

  const downloadUrl = getCsvDownloadLink(
    "Documents/Justin_LePore_Resume_2022.pdf"
  );

  return (
    <footer className="w3-container w3-teal w3-center w3-margin-top">
      <p
        className="languagePos"
        onClick={() => updateLangFunc()}
        title={footerData.LanguageToolTip}
      >
        <i className="fa fa-language"></i> {footerData.LanguageText}
      </p>

      <p className="languagePos" title={footerData.FileTextToolTip}>
        <i className="fa fa-file"></i>{" "}
        <a
          className="linkProps"
          target="_blank"
          rel="noopener noreferrer"
          href={downloadUrl}
        >
          {" "}
          {footerData.FileText}
        </a>
      </p>

      <div className="footerPos ">
        <p>{footerData.FindText}</p>
        <i
          className="fa fa-facebook-official w3-hover-opacity socialSize socialSpacing"
          title="Facebook"
          onClick={() =>
            window.open("https://www.facebook.com/justin.lepore.3/", "_blank")
          }
        ></i>
        <i
          className="fa fa-linkedin w3-hover-opacity socialSize"
          title="LinkedIn"
          onClick={() =>
            window.open("https://www.linkedin.com/in/justin-lepore/", "_blank")
          }
        ></i>
        <p>
          {footerData.ANTFText}{" "}
          <a href="https://antfcorp.com" rel="noreferrer" target="_blank">
            {footerData.ANTFName}
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
